// =============================>
//  NOT TO BE EDITED
//  These variables are derived based on the variables you set in the '_variables-wdpk.scss' file and hence there is no need to change these.
// @since Version 1.0.0
// <============================


// =============================>
// Constants - Should Not Be Changed
// ** THESE ARE IMPORTANT FOR THE FUNCTIONING
// ** OF THE THEME DO NOT CHANGE THESE
// <=============================
// Light Theme 20% Darker
$wdpk-light-20: rgba(0, 0, 0, .2);
// Light Theme 30% Darker
$wdpk-light-30: rgba(0, 0, 0, .3);
// Light Theme 40% Darker
$wdpk-light-40: rgba(0, 0, 0, .4);
// Light Theme 50% Darker
$wdpk-light-50: rgba(0, 0, 0, .5);
// Light Theme 60% Darker
$wdpk-light-60: rgba(0, 0, 0, .6);
// Dark Theme 20% Lighter
$wdpk-dark-20: rgba(255, 255, 255, .2);
// Dark Theme 30% Lighter
$wdpk-dark-30: rgba(255, 255, 255, .3);
// Dark Theme 40% Lighter
$wdpk-dark-40: rgba(255, 255, 255, .4);
// Dark Theme 50% Lighter
$wdpk-dark-50: rgba(255, 255, 255, .5);
// Dark Theme 60% Lighter
$wdpk-dark-60: rgba(255, 255, 255, .6);

