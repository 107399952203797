// =============================>
//  Partial For Social Sharing
// <============================

// =============================>
//  Things to be done
// <============================
// TODO: Create new demos for the social sharing buttons with counters
// TODO: Document the library properly with all the features

// Importing the relevant themes from the the parent theme offered by jsSocials

@if ($wdpk-social-sharing-theme == 'classic')
{
	.jssocials-share-twitter .jssocials-share-link
	{
		background: #00aced;
		border:     1px solid #0099d4;
	}
	.jssocials-share-twitter .jssocials-share-link:hover
	{
		background: #0087ba;
	}

	.jssocials-share-facebook .jssocials-share-link
	{
		background: #3b5998;
		border:     1px solid #344e86;
	}
	.jssocials-share-facebook .jssocials-share-link:hover
	{
		background: #2d4373;
	}

	.jssocials-share-googleplus .jssocials-share-link
	{
		background: #dd4b39;
		border:     1px solid #d73925;
	}
	.jssocials-share-googleplus .jssocials-share-link:hover
	{
		background: #c23321;
	}

	.jssocials-share-linkedin .jssocials-share-link
	{
		background: #007bb6;
		border:     1px solid #006a9d;
	}
	.jssocials-share-linkedin .jssocials-share-link:hover
	{
		background: #005983;
	}

	.jssocials-share-pinterest .jssocials-share-link
	{
		background: #cb2027;
		border:     1px solid #b51d23;
	}
	.jssocials-share-pinterest .jssocials-share-link:hover
	{
		background: #9f191f;
	}

	.jssocials-share-email .jssocials-share-link
	{
		background: #3490f3;
		border:     1px solid #1c83f2;
	}
	.jssocials-share-email .jssocials-share-link:hover
	{
		background: #0e76e6;
	}

	.jssocials-share-stumbleupon .jssocials-share-link
	{
		background: #eb4823;
		border:     1px solid #e03a14;
	}
	.jssocials-share-stumbleupon .jssocials-share-link:hover
	{
		background: #c93412;
	}

	.jssocials-share-whatsapp .jssocials-share-link
	{
		background: #29a628;
		border:     1px solid #249123;
	}
	.jssocials-share-whatsapp .jssocials-share-link:hover
	{
		background: #1f7d1e;
	}

	.jssocials-share-telegram .jssocials-share-link
	{
		background: #2ca5e0;
		border:     1px solid #1f98d3;
	}
	.jssocials-share-telegram .jssocials-share-link:hover
	{
		background: #1c88bd;
	}

	.jssocials-share-line .jssocials-share-link
	{
		background: #25af00;
		border:     1px solid #209600;
	}
	.jssocials-share-line .jssocials-share-link:hover
	{
		background: #1a7c00;
	}

	.jssocials-share-viber .jssocials-share-link
	{
		background: #7b519d;
		border:     1px solid #6e488c;
	}
	.jssocials-share-viber .jssocials-share-link:hover
	{
		background: #61407b;
	}

	.jssocials-share-pocket .jssocials-share-link
	{
		background: #ef4056;
		border:     1px solid #ed2841;
	}
	.jssocials-share-pocket .jssocials-share-link:hover
	{
		background: #e9132e;
	}

	.jssocials-share-messenger .jssocials-share-link
	{
		background: #0084ff;
		border:     1px solid #0077e6;
	}
	.jssocials-share-messenger .jssocials-share-link:hover
	{
		background: #006acc;
	}

	.jssocials-share-vkontakte .jssocials-share-link
	{
		background: #45668e;
		border:     1px solid #3d5a7d;
	}
	.jssocials-share-vkontakte .jssocials-share-link:hover
	{
		background: #344d6c;
	}

	.jssocials-share-rss .jssocials-share-link
	{
		background: #ff9900;
		border:     1px solid #e68a00;
	}
	.jssocials-share-rss .jssocials-share-link:hover
	{
		background: #cc7a00;
	}

	.jssocials-share-link
	{
		padding:            .5em .6em;
		border-radius:      0.3em;
		box-shadow:         inset 0 1px 3px rgba(255, 255, 255, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.15);
		color:              #fff;
		-webkit-transition: background 200ms ease-in-out;
		transition:         background 200ms ease-in-out;
	}
	.jssocials-share-link:hover, .jssocials-share-link:focus, .jssocials-share-link:active
	{
		color: #fff;
	}

	.jssocials-share-count-box
	{
		position:      relative;
		height:        2.5em;
		padding:       0 .3em;
		margin-left:   6px;
		border-radius: 0.3em;
		border:        1px solid #dcdcdc;
		box-shadow:    0 1px 3px rgba(0, 0, 0, 0.15);
	}
	.jssocials-share-count-box:before
	{
		content:           "";
		position:          absolute;
		top:               1em;
		left:              -9px;
		display:           block;
		width:             0;
		border-style:      solid;
		border-width:      6px 6px 0;
		border-color:      #dcdcdc transparent;
		-webkit-transform: rotate(90deg);
		-ms-transform:     rotate(90deg);
		transform:         rotate(90deg);
	}
	.jssocials-share-count-box:after
	{
		content:           "";
		position:          absolute;
		top:               1em;
		left:              -7px;
		display:           block;
		width:             0;
		border-style:      solid;
		border-width:      5px 5px 0;
		border-color:      #fff transparent;
		-webkit-transform: rotate(90deg);
		-ms-transform:     rotate(90deg);
		transform:         rotate(90deg);
	}
	.jssocials-share-count-box .jssocials-share-count
	{
		line-height: 2.75em;
		color:       #444;
		font-size:   .9em;
	}
} @else if ($wdpk-social-sharing-theme == 'flat')
{
	.jssocials-share-twitter .jssocials-share-link
	{
		background: #00aced;
	}
	.jssocials-share-twitter .jssocials-share-link:hover
	{
		background: #0087ba;
	}

	.jssocials-share-facebook .jssocials-share-link
	{
		background: #3b5998;
	}
	.jssocials-share-facebook .jssocials-share-link:hover
	{
		background: #2d4373;
	}

	.jssocials-share-googleplus .jssocials-share-link
	{
		background: #dd4b39;
	}
	.jssocials-share-googleplus .jssocials-share-link:hover
	{
		background: #c23321;
	}

	.jssocials-share-linkedin .jssocials-share-link
	{
		background: #007bb6;
	}
	.jssocials-share-linkedin .jssocials-share-link:hover
	{
		background: #005983;
	}

	.jssocials-share-pinterest .jssocials-share-link
	{
		background: #cb2027;
	}
	.jssocials-share-pinterest .jssocials-share-link:hover
	{
		background: #9f191f;
	}

	.jssocials-share-email .jssocials-share-link
	{
		background: #3490f3;
	}
	.jssocials-share-email .jssocials-share-link:hover
	{
		background: #0e76e6;
	}

	.jssocials-share-stumbleupon .jssocials-share-link
	{
		background: #eb4823;
	}
	.jssocials-share-stumbleupon .jssocials-share-link:hover
	{
		background: #c93412;
	}

	.jssocials-share-whatsapp .jssocials-share-link
	{
		background: #29a628;
	}
	.jssocials-share-whatsapp .jssocials-share-link:hover
	{
		background: #1f7d1e;
	}

	.jssocials-share-telegram .jssocials-share-link
	{
		background: #2ca5e0;
	}
	.jssocials-share-telegram .jssocials-share-link:hover
	{
		background: #1c88bd;
	}

	.jssocials-share-line .jssocials-share-link
	{
		background: #25af00;
	}
	.jssocials-share-line .jssocials-share-link:hover
	{
		background: #1a7c00;
	}

	.jssocials-share-viber .jssocials-share-link
	{
		background: #7b519d;
	}
	.jssocials-share-viber .jssocials-share-link:hover
	{
		background: #61407b;
	}

	.jssocials-share-pocket .jssocials-share-link
	{
		background: #ef4056;
	}
	.jssocials-share-pocket .jssocials-share-link:hover
	{
		background: #e9132e;
	}

	.jssocials-share-messenger .jssocials-share-link
	{
		background: #0084ff;
	}
	.jssocials-share-messenger .jssocials-share-link:hover
	{
		background: #006acc;
	}

	.jssocials-share-vkontakte .jssocials-share-link
	{
		background: #45668e;
	}
	.jssocials-share-vkontakte .jssocials-share-link:hover
	{
		background: #344d6c;
	}

	.jssocials-share-rss .jssocials-share-link
	{
		background: #ff9900;
	}
	.jssocials-share-rss .jssocials-share-link:hover
	{
		background: #cc7a00;
	}

	.jssocials-share-link
	{
		padding:            .5em .6em;
		color:              #fff;
		-webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-link:hover, .jssocials-share-link:focus, .jssocials-share-link:active
	{
		color: #fff;
	}

	.jssocials-share-count-box
	{
		position:           relative;
		height:             2.5em;
		padding:            0 .3em;
		margin-left:        0.3em;
		background:         #f5f5f5;
		-webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-count-box:hover
	{
		background: gainsboro;
	}
	.jssocials-share-count-box:hover:after
	{
		border-color: transparent gainsboro transparent transparent;
	}
	.jssocials-share-count-box:after
	{
		content:            "";
		display:            block;
		position:           absolute;
		top:                0.85em;
		left:               -0.3em;
		width:              0;
		height:             0;
		border-width:       0.4em 0.4em 0.4em 0;
		border-style:       solid;
		border-color:       transparent #f5f5f5 transparent transparent;
		-webkit-transform:  rotate(360deg);
		-ms-transform:      rotate(360deg);
		transform:          rotate(360deg);
		-webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-count-box .jssocials-share-count
	{
		line-height: 2.5em;
		color:       #444;
	}

} @else if ($wdpk-social-sharing-theme == 'minima')
{
	.jssocials-share-twitter .jssocials-share-link
	{
		color: #00aced;
	}
	.jssocials-share-twitter .jssocials-share-link .jssocials-share-label
	{
		color: #00aced;
	}
	.jssocials-share-twitter .jssocials-share-link:hover, .jssocials-share-twitter .jssocials-share-link:focus, .jssocials-share-twitter .jssocials-share-link:active
	{
		color: #0087ba;
	}
	.jssocials-share-twitter .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:active .jssocials-share-label
	{
		color: #0087ba;
	}

	.jssocials-share-facebook .jssocials-share-link
	{
		color: #3b5998;
	}
	.jssocials-share-facebook .jssocials-share-link .jssocials-share-label
	{
		color: #3b5998;
	}
	.jssocials-share-facebook .jssocials-share-link:hover, .jssocials-share-facebook .jssocials-share-link:focus, .jssocials-share-facebook .jssocials-share-link:active
	{
		color: #2d4373;
	}
	.jssocials-share-facebook .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:active .jssocials-share-label
	{
		color: #2d4373;
	}

	.jssocials-share-googleplus .jssocials-share-link
	{
		color: #dd4b39;
	}
	.jssocials-share-googleplus .jssocials-share-link .jssocials-share-label
	{
		color: #dd4b39;
	}
	.jssocials-share-googleplus .jssocials-share-link:hover, .jssocials-share-googleplus .jssocials-share-link:focus, .jssocials-share-googleplus .jssocials-share-link:active
	{
		color: #c23321;
	}
	.jssocials-share-googleplus .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:active .jssocials-share-label
	{
		color: #c23321;
	}

	.jssocials-share-linkedin .jssocials-share-link
	{
		color: #007bb6;
	}
	.jssocials-share-linkedin .jssocials-share-link .jssocials-share-label
	{
		color: #007bb6;
	}
	.jssocials-share-linkedin .jssocials-share-link:hover, .jssocials-share-linkedin .jssocials-share-link:focus, .jssocials-share-linkedin .jssocials-share-link:active
	{
		color: #005983;
	}
	.jssocials-share-linkedin .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:active .jssocials-share-label
	{
		color: #005983;
	}

	.jssocials-share-pinterest .jssocials-share-link
	{
		color: #cb2027;
	}
	.jssocials-share-pinterest .jssocials-share-link .jssocials-share-label
	{
		color: #cb2027;
	}
	.jssocials-share-pinterest .jssocials-share-link:hover, .jssocials-share-pinterest .jssocials-share-link:focus, .jssocials-share-pinterest .jssocials-share-link:active
	{
		color: #9f191f;
	}
	.jssocials-share-pinterest .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:active .jssocials-share-label
	{
		color: #9f191f;
	}

	.jssocials-share-email .jssocials-share-link
	{
		color: #3490f3;
	}
	.jssocials-share-email .jssocials-share-link .jssocials-share-label
	{
		color: #3490f3;
	}
	.jssocials-share-email .jssocials-share-link:hover, .jssocials-share-email .jssocials-share-link:focus, .jssocials-share-email .jssocials-share-link:active
	{
		color: #0e76e6;
	}
	.jssocials-share-email .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-email .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-email .jssocials-share-link:active .jssocials-share-label
	{
		color: #0e76e6;
	}

	.jssocials-share-stumbleupon .jssocials-share-link
	{
		color: #eb4823;
	}
	.jssocials-share-stumbleupon .jssocials-share-link .jssocials-share-label
	{
		color: #eb4823;
	}
	.jssocials-share-stumbleupon .jssocials-share-link:hover, .jssocials-share-stumbleupon .jssocials-share-link:focus, .jssocials-share-stumbleupon .jssocials-share-link:active
	{
		color: #c93412;
	}
	.jssocials-share-stumbleupon .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-stumbleupon .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-stumbleupon .jssocials-share-link:active .jssocials-share-label
	{
		color: #c93412;
	}

	.jssocials-share-whatsapp .jssocials-share-link
	{
		color: #29a628;
	}
	.jssocials-share-whatsapp .jssocials-share-link .jssocials-share-label
	{
		color: #29a628;
	}
	.jssocials-share-whatsapp .jssocials-share-link:hover, .jssocials-share-whatsapp .jssocials-share-link:focus, .jssocials-share-whatsapp .jssocials-share-link:active
	{
		color: #1f7d1e;
	}
	.jssocials-share-whatsapp .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-whatsapp .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-whatsapp .jssocials-share-link:active .jssocials-share-label
	{
		color: #1f7d1e;
	}

	.jssocials-share-telegram .jssocials-share-link
	{
		color: #2ca5e0;
	}
	.jssocials-share-telegram .jssocials-share-link .jssocials-share-label
	{
		color: #2ca5e0;
	}
	.jssocials-share-telegram .jssocials-share-link:hover, .jssocials-share-telegram .jssocials-share-link:focus, .jssocials-share-telegram .jssocials-share-link:active
	{
		color: #1c88bd;
	}
	.jssocials-share-telegram .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-telegram .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-telegram .jssocials-share-link:active .jssocials-share-label
	{
		color: #1c88bd;
	}

	.jssocials-share-line .jssocials-share-link
	{
		color: #25af00;
	}
	.jssocials-share-line .jssocials-share-link .jssocials-share-label
	{
		color: #25af00;
	}
	.jssocials-share-line .jssocials-share-link:hover, .jssocials-share-line .jssocials-share-link:focus, .jssocials-share-line .jssocials-share-link:active
	{
		color: #1a7c00;
	}
	.jssocials-share-line .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-line .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-line .jssocials-share-link:active .jssocials-share-label
	{
		color: #1a7c00;
	}

	.jssocials-share-viber .jssocials-share-link
	{
		color: #7b519d;
	}
	.jssocials-share-viber .jssocials-share-link .jssocials-share-label
	{
		color: #7b519d;
	}
	.jssocials-share-viber .jssocials-share-link:hover, .jssocials-share-viber .jssocials-share-link:focus, .jssocials-share-viber .jssocials-share-link:active
	{
		color: #61407b;
	}
	.jssocials-share-viber .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-viber .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-viber .jssocials-share-link:active .jssocials-share-label
	{
		color: #61407b;
	}

	.jssocials-share-pocket .jssocials-share-link
	{
		color: #ef4056;
	}
	.jssocials-share-pocket .jssocials-share-link .jssocials-share-label
	{
		color: #ef4056;
	}
	.jssocials-share-pocket .jssocials-share-link:hover, .jssocials-share-pocket .jssocials-share-link:focus, .jssocials-share-pocket .jssocials-share-link:active
	{
		color: #e9132e;
	}
	.jssocials-share-pocket .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-pocket .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-pocket .jssocials-share-link:active .jssocials-share-label
	{
		color: #e9132e;
	}

	.jssocials-share-messenger .jssocials-share-link
	{
		color: #0084ff;
	}
	.jssocials-share-messenger .jssocials-share-link .jssocials-share-label
	{
		color: #0084ff;
	}
	.jssocials-share-messenger .jssocials-share-link:hover, .jssocials-share-messenger .jssocials-share-link:focus, .jssocials-share-messenger .jssocials-share-link:active
	{
		color: #006acc;
	}
	.jssocials-share-messenger .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-messenger .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-messenger .jssocials-share-link:active .jssocials-share-label
	{
		color: #006acc;
	}

	.jssocials-share-vkontakte .jssocials-share-link
	{
		color: #45668e;
	}
	.jssocials-share-vkontakte .jssocials-share-link .jssocials-share-label
	{
		color: #45668e;
	}
	.jssocials-share-vkontakte .jssocials-share-link:hover, .jssocials-share-vkontakte .jssocials-share-link:focus, .jssocials-share-vkontakte .jssocials-share-link:active
	{
		color: #344d6c;
	}
	.jssocials-share-vkontakte .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-vkontakte .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-vkontakte .jssocials-share-link:active .jssocials-share-label
	{
		color: #344d6c;
	}

	.jssocials-share-rss .jssocials-share-link
	{
		color: #ff9900;
	}
	.jssocials-share-rss .jssocials-share-link .jssocials-share-label
	{
		color: #ff9900;
	}
	.jssocials-share-rss .jssocials-share-link:hover, .jssocials-share-rss .jssocials-share-link:focus, .jssocials-share-rss .jssocials-share-link:active
	{
		color: #cc7a00;
	}
	.jssocials-share-rss .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-rss .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-rss .jssocials-share-link:active .jssocials-share-label
	{
		color: #cc7a00;
	}

	.jssocials-share-link
	{
		-webkit-transition: color 200ms ease-in-out;
		transition:         color 200ms ease-in-out;
	}
	.jssocials-share-link.jssocials-share-link-count .jssocials-share-count
	{
		font-size: .5em;
	}

	.jssocials-share-label
	{
		font-size:          0.8em;
		color:              #444;
		-webkit-transition: color 200ms ease-in-out;
		transition:         color 200ms ease-in-out;
	}

	.jssocials-share-count-box
	{
		margin-left:   0.3em;
		border:        1px solid #efefef;
		border-radius: 0.3em;
	}
	.jssocials-share-count-box .jssocials-share-count
	{
		line-height: 1.8em;
		font-size:   0.8em;
		color:       #444;
	}

} @else if ($wdpk-social-sharing-theme == 'outlined')
{
	.jssocials-share-link
	{
		padding:            .5em .6em;
		border-radius:      0.3em;
		border:             2px solid #acacac;
		color:              #acacac;
		-webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-link:hover, .jssocials-share-link:focus, .jssocials-share-link:active
	{
		border: 2px solid #939393;
		color:  #939393;
	}

	.jssocials-share-count-box
	{
		position:           relative;
		height:             2.5em;
		padding:            0 .3em;
		margin-left:        0.4em;
		background:         #f5f5f5;
		border-radius:      0.3em;
		-webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-count-box:hover
	{
		background: #e8e8e8;
	}
	.jssocials-share-count-box:hover:after
	{
		border-color: transparent #e8e8e8 transparent transparent;
	}
	.jssocials-share-count-box:after
	{
		content:            "";
		display:            block;
		position:           absolute;
		top:                0.75em;
		left:               -0.4em;
		width:              0;
		height:             0;
		border-width:       0.5em 0.5em 0.5em 0;
		border-style:       solid;
		border-color:       transparent #f5f5f5 transparent transparent;
		-webkit-transform:  rotate(360deg);
		-ms-transform:      rotate(360deg);
		transform:          rotate(360deg);
		-webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
		transition:         background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
	}
	.jssocials-share-count-box .jssocials-share-count
	{
		line-height: 2.5em;
		color:       #acacac;
	}
}

// =============================>
//  JsSocials CSS Override
// <============================

.jssocials-share
{
	overflow: hidden;

	.jssocials-share-link
	{
		padding:            $wdpk-social-sharing-vertical-padding $wdpk-social-sharing-horizontal-padding;
		border-radius:      $wdpk-social-sharing-border-radius;
		-webkit-box-shadow: none;
		box-shadow:         none; //reset any box shadow that has been added by the plugin
		@if ($wdpk-social-sharing-theme == 'classic')
		{
			box-shadow:         0 0 ($wdpk-social-sharing-horizontal-padding * .4) rgba(0, 0, 0, 0.3) inset;
			-webkit-box-shadow: 0 0 ($wdpk-social-sharing-horizontal-padding * .4) rgba(0, 0, 0, 0.3) inset;
		}
		@include wdpk-shadow($wdpk-social-sharing-shadow);
		text-decoration:    none;
		font-size:          $wdpk-social-sharing-font-size;
		line-height:        $wdpk-social-sharing-line-height;
		-webkit-transition: all $wdpk-global-transition-duration $wdpk-global-transition-function;
		-moz-transition:    all $wdpk-global-transition-duration $wdpk-global-transition-function;
		-ms-transition:     all $wdpk-global-transition-duration $wdpk-global-transition-function;
		-o-transition:      all $wdpk-global-transition-duration $wdpk-global-transition-function;
		transition:         all $wdpk-global-transition-duration $wdpk-global-transition-function;
		color: $wdpk-social-sharing-font-color;
		border-color: $wdpk-social-sharing-border-color;


		&:hover
		{
			text-decoration: none;
		}

		&:hover i
		{
			@include bounceInDown(
					$duration: .8s,
					$count: 1,
					$delay: 0s,
					$function: ease,
					$fill: both
			);
		}

		i
		{
			font-size:   $wdpk-social-sharing-font-size * .86;
			line-height: $wdpk-social-sharing-line-height * .86;
		}

		.jssocials-share-label
		{
			padding-left: $wdpk-social-sharing-horizontal-padding * .4;
		}

	}
}