// =============================>
//  Progress Bars Styling Partial
// <============================

.progress
{
	margin: $wdpk-progress-bar-x-margin auto;
}

.wdpk-width-animated
{
	.progress-bar
	{
		-webkit-transition: none !important;
		-o-transition:      none !important;
		transition:         none !important;
		width:              0;
	}
}