////////////////////////////////////////////////
// If you don't understand what's going on here,
// please read the README first.
////////////////////////////////////////////////

// Wraps the content block provided with a media query
// with min-width equal to the left edge of the left slice
// and max-width equal to the right edge of the right slice
//
// between($slice-left, $slice-right, $no-query: false)
// - $slice-left  : <slice number> A number of the left slice. Should be a positive integer.
// - $slice-right : <slice number> A number of the left slice. Should be a positive integer.
// - $no-query    : [<.class>]     A class to provide the no-query fallback (see Breakpoint docs).
@mixin between($slice-left, $slice-right, $no-query: false) {

  // Parsing slice names.
  $slice-left: get-slice-id($slice-left);
  $slice-right: get-slice-id($slice-right);

  // If the slices provided are the first and the last one,
  // the breakpoint becomes meaningless
  @if ($slice-left == 1) and ($slice-right == total-slices()) {
    //Thus, don't wrap the code block with a media query
    @content; }

  @else {

    // Retrieving the left edge of the left slice
    $context:   left-bp-of-slice($slice-left);

    // Retrieving the right edge of the slice
    // unless the slice is the last one
    @if $slice-right < total-slices() {
      $right:   right-bp-of-slice($slice-right);
      $context: append($context, $right); }

    // Setting the breakpoint
    @include breakpoint($context, $no-query) {
      @content; } } }


// Wraps the content block provided with a media query
// with min-width and max-width equal to the edges of a slice
//
// at($slice, $no-query: false)
// - $slice : <slice number> A number of a slice. Should be a positive integer.
// - $no-query    : [<.class>]     A class to provide the no-query fallback (see Breakpoint docs).
@mixin at($slice, $no-query: false) {

  @include between($slice, $slice, $no-query) {
    @content; } }


// Wraps the content block provided with a media query
// with min-width equal to the right edge of a slice
//
// from($slice, $no-query: false)
// - $slice : <slice number> A number of a slice. Should be a positive integer.
// - $no-query    : [<.class>]     A class to provide the no-query fallback (see Breakpoint docs).
@mixin from($slice, $no-query: false) {

  @include between($slice, total-slices(), $no-query) {
    @content; } }


// Wraps the content block provided with a media query
// with max-width equal to the right edge of a slice
//
// to($slice, $no-query: false)
// - $slice : <slice number> A number of a slice. Should be a positive integer.
// - $no-query    : [<.class>]     A class to provide the no-query fallback (see Breakpoint docs).
@mixin to($slice, $no-query: false) {

  @include between(1, $slice, $no-query) {
    @content; } }


