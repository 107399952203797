// Returns a correction value for the given breakpoint
// to prevent media query overlapping.
//
// Relies on the global variable $slicer-anti-overlap-corrections
// that should contain a list of allowed correction values.
//
// Returns either only positive or only negative value.
//
// anti-overlap-correction($bp, $positive: true)
//   - $bp       : a breakpoint, e. g. 800px or 20em
//   - $positive : whether to return only positive or only negative values
//                 * true - return only positive correction value (default)
//                 * false - return only negative correction value
@function anti-overlap-correction($bp, $positive: true) {
  @each $correction in $slicer-anti-overlap-corrections {
    @if (unit($bp) == unit($correction)) and (($correction > 0) == $positive) {
      @return $correction; } }
  @return 0; }


// total-slices()
//
// Accepts no arguments.
//
// Returns total number of slices
// (which is equal to total number of breakpoints).
@function total-slices() {
  @return length($slicer-breakpoints); }


// left-bp-of-slice($slice)
// - $slice : <slice number> A number of a slice. Should be positive integer.
//
// Returns the left breakpoint of an Nth slice, e. g. `600px`.
@function left-bp-of-slice($slice) {
  $slice: get-slice-id($slice);
  $min: 1;
  $max: total-slices();

  // Making sure that the slice provided is valid
  @if ($slice < $min) or ($slice > $max) {

    @warn "Wrong Slice number provided: #{$slice}. Should be between #{$min} and #{$max}."; }

  @else {

    $left: i am declared;

    // Special treatment of the first slice...
    @if $slice == 1 {
      // ...to prevent a meaningless `min-width: 0` meida query
      $left:  max-width; } // This is Breakpoint's syntax

    @else {
      // Returning the left edge of the slice
      $left:  nth($slicer-breakpoints, $slice);
      $left:  $left + anti-overlap-correction($left, true); }

    @return $left; } }


// right-bp-of-slice($slice)
// - $slice : <slice number> A number of a slice. Should be positive integer.
//
// Returns the right breakpoint of an Nth slice, e. g. `800px`.
@function right-bp-of-slice($slice) {
  $slice: get-slice-id($slice);
  $min: 1;
  $max: total-slices() - 1;

  // Making sure that the slice provided is valid
  @if $max == total-slices() {
    @warn "Slice number provided: #{$slice}. It's the last slice, it has no right edge."; }
  @else if ($slice < $min) or ($slice > $max) {
    @warn "Wrong column number provided: #{$slice}. Should be between #{$min} and #{$max}."; }
  @else {
    // Reading the right edge of the slice
    $right-bp: nth($slicer-breakpoints, $slice + 1);
    @return $right-bp + anti-overlap-correction($right-bp, false); } }


// bp($slice)
//
// A shortcut for nth($slicer-breakpoints, $slice)
@function bp($slice) {
  @return nth($slicer-breakpoints, $slice); }


// lbp($slice)
//
// A shortcut for left-bp-of-slice($slice)
@function lbp($slice) {
  @return left-bp-of-slice($slice); }


// rbp($slice)
//
// A shortcut for right-bp-of-slice($slice)
@function rbp($slice) {
  @return right-bp-of-slice($slice); }


// get-slice-id($slice-id-or-name)
//
// Returns a slice number corresponding to a slice name
//
// - $slice-id-or-name: <slice number or name> Should be either a number of a slice (positive integer)
//                                             or a name of the slice from $slicer-breakpoint-names (string)
@function get-slice-id($slice-id-or-name) {

  // Treating the argument as a slice name.
  // Retrieving slice id for given slice name
  $slice-id: index($slicer-breakpoint-names, $slice-id-or-name);

  // Checking whether a slice with the given name exists
  @if $slice-id {
    //@warn "slice-id is truthful"

    // Return the id found
    @return $slice-id; }
  @else {
    //@warn "slice-id is falsy"

    // Assume an id was given in the first place
    @return $slice-id-or-name; } }


// prev-slice($slice-id-or-name)
//
// Returns previous slice id for the given slice
@function prev-slice($slice-id-or-name) {

  $slice-id: get-slice-id($slice-id-or-name);

  @if $slice-id > 1 {
    $slice-id: $slice-id - 1; }
  @else {
    @warn "Previous slice for the first slice requested"; }

  @return $slice-id; }


// next-slice($slice-id-or-name)
//
// Returns next slice id for the given slice
@function next-slice($slice-id-or-name) {

  $slice-id: get-slice-id($slice-id-or-name);

  @if $slice-id < total-slices() {
    $slice-id: $slice-id + 1; }
  @else {
    @warn "Previous slice for the first slice requested"; }

  @return $slice-id; }
