// Core variables and mixins
@import "lg-variables";
@import "lg-mixins";
@import "lg-fonts";
@import "lg-theme-default";
@import "lg-thumbnail";
@import "lg-video";
@import "lg-autoplay";
@import "lg-zoom";
@import "lg-pager";
@import "lg-fullscreen";
@import "lg-share";

// Core
@import "lg-core";
