// =============================>
// Styling Partial For Typing Text
// <============================

/* Cursor */
.typed-cursor
{
}

/* If fade out option is set */
.typed-fade-out
{
}


.wdpk-typing-text
{
	font-size:   30px;
	line-height: 1.3;
	text-align:  center;
	font-family: inherit;
	color:       inherit;
}