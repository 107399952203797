// =============================>
//  Import all Shortcode Partials
// <============================
// TODO: Create a common file for components so that user can choose the ones that he wants to keep to optimize the CSS files
@import "wdpk-prism";
@import "accordion";
@import "sidebar";
@import "list-group";
@import "alerts";
@import "animations";
@import "blockquotes";
@import "navpills";
@import "breadcrumb";
@import "buttons";
@import "cards";
@import "image-hovers";
@import "social-sharing";
@import "carousel";
@import "clients";
@import "timer";
@import "counter";
@import "circles";
@import "infobox";
@import "forms";
@import "dropdowns";
@import "comments";
@import "progress-bars";
@import "pagination";
@import "parallax";
@import "heading";
@import "lightbox";
@import "jumbotron";
@import "preloader";
@import "typing-text";
@import "section-separator";
@import "dropcaps";
@import "scroll-to-top";
@import "info-card";