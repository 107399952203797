// =============================>
// Includes All Third Party CSS Dependencies
// <==============================

@import "../../_dependencies/bootstrap/scss/bootstrap.scss";
@import "../../_dependencies/breakpoint-sass/stylesheets/_breakpoint.scss";
@import "../../_dependencies/breakpoint-slicer/stylesheets/_breakpoint-slicer.sass";
@import "../../_dependencies/sass-rem/_rem.scss";
@import "../../_dependencies/animatewithsass/animate.scss";
@import "../../_dependencies/imagehover.css/scss/imagehover";
@import "../../_dependencies/jssocials/dist/jssocials";
@import "../../_dependencies/flickity/dist/flickity";
@import "../../_dependencies/plyr/src/sass/plyr";
@import "../../_dependencies/lightgallery/src/sass/lightgallery";
@import "../../_dependencies/aos/dist/aos";
@import "../../_dependencies/vegas/dist/vegas";
@import "../../_dependencies/prism/prism";
@import "../../_dependencies/hover.css/css/hover-min";



