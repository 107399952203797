// =============================>
//  Styling Partial For Section Separator 
// <============================

.wdpk-section
{
	&:before, &:after
	{

		position:       absolute;
		content:        '';
		pointer-events: none;
	}
}

/* Triangles */
.wdpk-section-triangles::before,
.wdpk-section-triangles::after
{
	left:              50%;
	width:             100px;
	height:            100px;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform:         translateX(-50%) rotate(45deg);
}

.wdpk-section-triangles::before
{
	top:        -50px;
	background: inherit;
}

.wdpk-section-triangles::after
{
	bottom:     -50px;
	z-index:    10;
	background: inherit;
}

/* Double Diagonal line */

.wdpk-section-doublediagonal
{
	z-index:     1;
	padding-top: 6em;
	background:  #2072a7;
}

.wdpk-section-doublediagonal::before,
.wdpk-section-doublediagonal::after
{
	top:                      0;
	left:                     -25%;
	z-index:                  -1;
	width:                    150%;
	height:                   75%;
	background:               inherit;
	-webkit-transform:        rotate(-2deg);
	transform:                rotate(-2deg);
	-webkit-transform-origin: 0 0;
	transform-origin:         0 0;
}

.wdpk-section-doublediagonal::before
{
	height:                   50%;
	background:               #116094;
	-webkit-transform:        rotate(-3deg);
	transform:                rotate(-3deg);
	-webkit-transform-origin: 3% 0;
	transform-origin:         3% 0;
}

/* Half Circle */
.wdpk-section-halfcircle::before,
.wdpk-section-halfcircle::after
{
	left:              50%;
	z-index:           10;
	width:             100px;
	height:            100px;
	border-radius:     50%;
	background:        inherit;
	-webkit-transform: translateX(-50%);
	transform:         translateX(-50%);
}

.wdpk-section-halfcircle::before
{
	top: -50px;
}

.wdpk-section-halfcircle::after
{
	bottom: -50px;
}

/* Big Triangle */
svg#bigTriangleColor
{
	pointer-events: none;
}

#bigTriangleColor path
{
	fill:         #3498db;
	stroke:       #3498db;
	stroke-width: 2;
}

/* Curves */
#curveUpColor path,
#curveDownColor path
{
	fill:   #3498db;
	stroke: #3498db;
}

/* Multiple Triangles */
.wdpk-section-multitriangles::before,
.wdpk-section-multitriangles::after
{
	left:              50%;
	width:             50px;
	height:            50px;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform:         translateX(-50%) rotate(45deg);
}

.wdpk-section-multitriangles::before
{
	top:        -25px;
	background: inherit;
	box-shadow: -50px 50px 0 #3498db, 50px -50px 0 #3498db;
}

.wdpk-section-multitriangles::after
{
	bottom:     -25px;
	z-index:    10;
	background: inherit;
	box-shadow: -50px 50px 0 #3498db, 50px -50px 0 #3498db;
}

/* Rounded corners */
.wdpk-section-roundedcorners::before
{
	top:           100%;
	left:          0;
	z-index:       10;
	width:         100%;
	height:        60px;
	border-radius: 0 0 60px 60px;
	background:    inherit;
}

/* Rounded center split and bottom rounded */
.wdpk-section-roundedsplit
{
	padding-top:   7em;
	border-radius: 0 0 80px 80px;
}

.wdpk-section-roundedsplit::before,
.wdpk-section-roundedsplit::after
{
	top:        -70px;
	left:       0;
	z-index:    10;
	width:      50%;
	height:     70px;
	background: inherit;
}

.wdpk-section-roundedsplit::before
{
	border-radius: 0 80px 0 0;
}

.wdpk-section-roundedsplit::after
{
	left:          50%;
	border-radius: 80px 0 0 0;
}

/* Inverted rounded corners */
.wdpk-section-invertedrounded
{
	margin-bottom: 90px;
	padding:       13em 10% 10em;
	border-radius: 0 0 0 90px;
}

.wdpk-section-invertedrounded::before,
.wdpk-section-invertedrounded::after
{
	left:       0;
	z-index:    -1;
	height:     90px;
	background: #3498db;
}

.wdpk-section-invertedrounded::before
{
	top:           100%;
	width:         100%;
	border-radius: 0 90px 0 0;
}

.wdpk-section-invertedrounded::after
{
	bottom:  0;
	z-index: -1;
	width:   50%;
}

/* Boxes */
.wdpk-section-boxes
{
	padding-top:    4em;
	padding-bottom: 13em;
}

.wdpk-section-boxes::before
{
	bottom:                  0;
	left:                    0;
	width:                   100%;
	height:                  50px;
	background-image:        -webkit-gradient(linear, 100% 0, 0 100%, color-stop(0.5, #3498db), color-stop(0.5, #2980b9));
	background-image:        linear-gradient(to right, #3498db 50%, #2980b9 50%);
	-webkit-background-size: 130px 100%;
	background-size:         130px 100%;
}

/* Zig Zag (3d up and simple down) */
.wdpk-section-zigzag::before,
.wdpk-section-zigzag::after
{
	right:           0;
	left:            0;
	z-index:         10;
	display:         block;
	height:          90px;
	background-size: 50px 100%;
}

.wdpk-section-zigzag::before
{
	top:                 -90px;
	background-image:    -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, transparent), color-stop(0.25, $wdpk-primary-color));
	background-image:    linear-gradient(315deg, $wdpk-primary-color 25%, transparent 25%),
						 linear-gradient(45deg, $wdpk-primary-color 25%, transparent 25%);
	background-position: 50%;
}

.wdpk-section-zigzag::after
{
	top:                 100%;
	background-image:    -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, $wdpk-primary-color), color-stop(0.25, $wdpk-primary-color));
	background-image:    linear-gradient(135deg, $wdpk-primary-color 25%, transparent 25%),
						 linear-gradient(225deg, $wdpk-primary-color 25%, transparent 25%);
	background-position: 50%;
}

/* Edges */
.wdpk-section-roundedges
{
	padding-bottom: 16em;
}

.wdpk-section-roundedges::before,
.wdpk-section-roundedges::after
{
	width:             200px;
	height:            200px;
	border-radius:     50%;
	background:        #2980b9;
	top:               -100px;
	-webkit-transform: rotate(45deg);
	transform:         rotate(45deg);
}

.wdpk-section-roundedges::before
{
	left: -100px;
}

.wdpk-section-roundedges::after
{
	right: -100px;
}

/* Slit */
.wdpk-section-slit
{
	padding-top: 5em;
}

.wdpk-section-slit::before,
.wdpk-section-slit::after
{
	width:      50.5%;
	width:      -webkit-calc(50% + 10px);
	width:      -moz-calc(50% + 10px);
	width:      calc(50% + 10px);
	height:     160px;
	background: #2980b9;
	top:        -160px;
}

.wdpk-section-slit::before
{
	left:              -20px;
	-webkit-transform: skewX(10deg);
	transform:         skewX(10deg);
	box-shadow:        -10px -20px #3192d3;
}

.wdpk-section-slit::after
{
	right:             -20px;
	-webkit-transform: skewX(-10deg);
	transform:         skewX(-10deg);
	box-shadow:        10px -20px #3192d3;
}

/* Big half circle */
#bigHalfCircle path
{
	fill:   #3498db;
	stroke: #3498db;
}

/* Big triangle with shadow */

#trianglePath1
{
	fill:   #3498db;
	stroke: #3498db;
}

#trianglePath2
{
	fill:   #2072a7;
	stroke: #2072a7;
}

/* Inclined Zig Zag */
.wdpk-section-inczigzag::before,
.wdpk-section-inczigzag::after
{
	left:            0;
	width:           100%;
	height:          50px;
	background-size: 100px 100%;
}

.wdpk-section-inczigzag::before
{
	top:              0;
	background-image: -webkit-gradient(linear, 0 0, 10% 100%, color-stop(0.5, #2980b9), color-stop(0.5, #3498db));
	background-image: linear-gradient(15deg, #3498db 50%, #2980b9 50%);
}

.wdpk-section-inczigzag::after
{
	bottom:           0;
	background-image: -webkit-gradient(linear, 0 0, 10% 100%, color-stop(0.5, #3498db), color-stop(0.5, #2980b9));
	background-image: linear-gradient(15deg, #2980b9 50%, #3498db 50%);
}

/* Castle */
.wdpk-section-castle::before
{
	left:             0;
	width:            100%;
	height:           50px;
	background-size:  200px 100%;
	top:              0;
	background-image: -webkit-linear-gradient(40deg, #3498db 50%, #2980b9 50%);
	background-image: linear-gradient(40deg, #3498db 50%, #2980b9 50%);
}

/* Alternative slit with inner shadow */
#slitPath1
{
	fill:         #3498db;
	stroke:       red;
	stroke-width: 0;
}

#slitPath2, #slitPath3
{
	fill: #2072a7;
}

/* Folded corner */
.wdpk-section-foldedcorner::before,
.wdpk-section-foldedcorner::after
{
	bottom: 0;
	width:  100px;
	height: 100px;
}

.wdpk-section-foldedcorner::before
{
	right:            0;
	background-image: -webkit-linear-gradient(top left, #37a2ea 50%, #3498db 50%);
	background-image: linear-gradient(315deg, #3498db 50%, #37a2ea 50%);
}

.wdpk-section-foldedcorner::after
{
	right:            100px;
	background-image: -webkit-linear-gradient(top left, transparent 50%, #236fa1 50%);
	background-image: linear-gradient(315deg, #236fa1 50%, transparent 50%);
}

/* Dots */
.wdpk-section-dots::before
{
	bottom:            20px;
	width:             14px;
	height:            14px;
	border-radius:     50%;
	left:              50%;
	-webkit-transform: translateX(-50%);
	transform:         translateX(-50%);
	background:        #2072a7;
	box-shadow:        30px 0 #2072a7, -30px 0 #2072a7;
}

/* Double Line */
.wdpk-section-doubleline::before
{
	bottom:            30px;
	width:             140px;
	height:            2px;
	left:              50%;
	-webkit-transform: translateX(-50%);
	transform:         translateX(-50%);
	background:        #2072a7;
	box-shadow:        0 30px 0 #2072a7;
}

/* Cross */
.wdpk-section-cross
{
	padding-bottom: 15em;
}

.wdpk-section-cross::before,
.wdpk-section-cross::after
{
	background:               #2072a7;
	bottom:                   70px;
	width:                    200px;
	height:                   2px;
	left:                     50%;
	-webkit-transform-origin: 50% 50%;
	transform-origin:         50% 50%;
}

.wdpk-section-cross::before
{
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform:         translateX(-50%) rotate(45deg);
}

.wdpk-section-cross::after
{
	-webkit-transform: translateX(-50%) rotate(-45deg);
	transform:         translateX(-50%) rotate(-45deg);
}
