// =============================>
// Override the Styling Of PrismJs
// <==============================
// TODO: Prism To be cutomised using theme options instead of offering static design system

@if ($wdpk-code-block-style == 'light')
{
	/**
* GHColors theme by Avi Aryan (http://aviaryan.in)
* Inspired by Github syntax coloring
*/

	code[class*="language-"],
	pre[class*="language-"] {
		color: #393A34;
		font-family: Menlo, Monaco, "Courier New", monospace;
		direction: ltr;
		text-align: left;
		white-space: pre;
		word-spacing: normal;
		word-break: normal;
		font-size: 0.95em;
		line-height: 1.2em;

		-moz-tab-size: 4;
		-o-tab-size: 4;
		tab-size: 4;

		-webkit-hyphens: none;
		-moz-hyphens: none;
		-ms-hyphens: none;
		hyphens: none;
	}

	pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
	code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
		background: #b3d4fc;
	}

	pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
	code[class*="language-"]::selection, code[class*="language-"] ::selection {
		background: #b3d4fc;
	}

	/* Code blocks */
	pre[class*="language-"] {
		padding: 2em;
		margin: .5em 0;
		overflow: auto;
		border: 1px solid $wdpk-gray-200;
		background-color: $wdpk-gray-100;
		border-radius: $wdpk-border-radius;
	}

	:not(pre) > code[class*="language-"],
	pre[class*="language-"] {
	}

	/* Inline code */
	:not(pre) > code[class*="language-"] {
		padding: .2em;
		padding-top: 1px; padding-bottom: 1px;
		background: #f8f8f8;
		border: 1px solid #dddddd;
	}

	.token.comment,
	.token.prolog,
	.token.doctype,
	.token.cdata {
		color: #999988; font-style: italic;
	}

	.token.namespace {
		opacity: .7;
	}

	.token.string,
	.token.attr-value {
		color: #e3116c;
	}
	.token.punctuation,
	.token.operator {
		color: #393A34; /* no highlight */
	}

	.token.entity,
	.token.url,
	.token.symbol,
	.token.number,
	.token.boolean,
	.token.variable,
	.token.constant,
	.token.property,
	.token.regex,
	.token.inserted {
		color: #36acaa;
	}

	.token.atrule,
	.token.keyword,
	.token.attr-name,
	.language-autohotkey .token.selector {
		color: #00a4db;
	}

	.token.function,
	.token.deleted,
	.language-autohotkey .token.tag {
		color: #9a050f;
	}

	.token.tag,
	.token.selector,
	.language-autohotkey .token.keyword {
		color: #00009f;
	}

	.token.important,
	.token.function,
	.token.bold {
		font-weight: bold;
	}

	.token.italic {
		font-style: italic;
	}

	.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span
	{
		background-color:   $wdpk-primary-color;
		color:              $wdpk-white;
		-webkit-box-shadow: none;
		box-shadow:         none;
		-webkit-transition: all $wdpk-global-transition-duration $wdpk-global-transition-function;
		-o-transition:      all $wdpk-global-transition-duration $wdpk-global-transition-function;
		transition:         all $wdpk-global-transition-duration $wdpk-global-transition-function;
		margin-right: 10px;

		&:hover
		{
			color:            $wdpk-white;
			background-color: scale_color($wdpk-primary-color, $lightness: $wdpk-lightness-value);
		}
	}

} @else if ($wdpk-code-block-style == 'dark')
{
	pre[class*="language-"],
	code[class*="language-"] {
		color: #a6a6a6;
		font-size: 13px;
		text-shadow: none;
		font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
		direction: ltr;
		text-align: left;
		white-space: pre;
		word-spacing: normal;
		word-break: normal;
		line-height: 1.5;
		-moz-tab-size: 4;
		-o-tab-size: 4;
		tab-size: 4;
		-webkit-hyphens: none;
		-moz-hyphens: none;
		-ms-hyphens: none;
		hyphens: none;
	}
	pre[class*="language-"]::selection,
	code[class*="language-"]::selection,
	pre[class*="language-"]::mozselection,
	code[class*="language-"]::mozselection {
		text-shadow: none;
		background: #212121;
	}
	@media print {
		pre[class*="language-"],
		code[class*="language-"] {
			text-shadow: none;
		}
	}
	pre[class*="language-"] {
		padding:    1em;
		margin:     .5em 0;
		overflow:   auto;
		background: #1c1c1c;
	}
	:not(pre) > code[class*="language-"] {
		padding: .1em .3em;
		border-radius: .3em;
		color: #db4c69;
		background: #2b2b2b;
	}
	/*********************************************************
	* Tokens
	*/
	.namespace {
		opacity: .7;
	}
	.token.comment,
	.token.prolog,
	.token.doctype,
	.token.cdata {
		color: #4d4d4d;
	}
	.token.punctuation {
		color: #999999;
	}
	.token.property,
	.token.tag,
	.token.boolean,
	.token.number,
	.token.constant,
	.token.symbol,
	.token.deleted {
		color: #990055;
	}
	.token.selector,
	.token.attr-name,
	.token.string,
	.token.char,
	.token.builtin,
	.token.inserted {
		color: #38c07b;
	}
	.token.operator,
	.token.entity,
	.token.url,
	.language-css .token.string,
	.style .token.string {
		color: #a67f59;
		background: #0c0c0c;
	}
	.token.atrule,
	.token.attr-value,
	.token.keyword {
		color: #0077aa;
	}
	.token.function {
		color: #dd4a68;
	}
	.token.regex,
	.token.important,
	.token.variable {
		color: #ee9900;
	}
	.token.important,
	.token.bold {
		font-weight: bold;
	}
	.token.italic {
		font-style: italic;
	}
	.token.entity {
		cursor: help;
	}
	/*********************************************************
	* Line highlighting
	*/
	pre[data-line] {
		position: relative;
	}
	pre[class*="language-"] > code[class*="language-"] {
		position: relative;
		z-index: 1;
	}
	.line-highlight {
		position: absolute;
		left: 0;
		right: 0;
		padding: inherit 0;
		margin-top: 1em;
		background: #2b2b2b;
		box-shadow: inset 5px 0 0 #737373;
		z-index: 0;
		pointer-events: none;
		line-height: inherit;
		white-space: pre;
	}

}